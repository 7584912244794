<template>
  <div class="inner-layout">

    <div class="searchP-title">
      <span class="color-1">{{ input }}</span>에 대한 통합검색 결과입니다.
    </div>

    <div class="searchP-heading-group">
      <div class="heading-3">꼬리TIP</div>
      <div v-if="tipCount > 8" class="link-arrow cursor-pointer" @click="more('tip')">더보기</div>
    </div>
    <div class="item-container column-4" v-if="this.tipList && this.tipList.length > 0">
      <card v-for="(item,index) in this.tipList" :item="item" :key="`tip-${item.boIdx}`" cardType="simple" @click="goDetail('tip', item.boIdx)"></card>
    </div>
    <div v-else class="noDataMessage">{{ $msg('nodata2') }}</div>


    <div class="searchP-heading-group">
      <div class="heading-3">연구일지</div>
      <div v-if="labCount > 8" class="link-arrow cursor-pointer" @click="more('lab')">더보기</div>
    </div>
    <div class="item-container column-4" v-if="this.labList && this.labList.length > 0">
      <card v-for="(item,index) in this.labList" :item="item" :key="`lab-${item.boIdx}`" cardType="simple" @click="goDetail('lab', item.boIdx)"></card>
    </div>
    <div v-else class="noDataMessage">{{ $msg('nodata2') }}</div>


    <div class="searchP-heading-group">
      <div class="heading-3">꼬뮤니티</div>
      <div v-if="ggomunityCount > 8" class="link-arrow cursor-pointer" @click="more('ggomunity')">더보기</div>
    </div>
    <div class="community-list" v-if="ggomunityList && ggomunityList.length > 0">
      <template v-for="(item, index) in ggomunityList">
        <div class="community-item" @click="goDetail('ggomunity', item.boIdx)" >
          <div v-if="item.thumbnail" class="item-img">
            <div class="thumb-img"><image-alt :src="item.thumbnail" altType="list"/></div>
          </div>
          <div class="item-con">
            <span class="color-1">[{{ item.categoryName }}]</span>
            <div class="item-title">{{ item.title }}</div>
            <div class="info">
              <span class="date">{{ $getDateFormat(item.createDate, 'Y.m.d') }}</span> · <span>댓글 {{item.commentCount}}ㆍ추천 {{item.likeCnt}}ㆍ조회 {{item.view}}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else class="noDataMessage">{{ $msg('nodata2') }}</div>
  </div>
</template>

<script>
import carouselMixin from "@/mixins/carouselMixin"
import listMixin from "@/mixins/listMixin"
export default {
  name: "search",
  mixins: [carouselMixin, listMixin],
  data() {
    return {
      input: this.$route.query.input,
      tipCount: 0,
      labCount: 0,
      mdCount: 0,
      ggomunityList: null,
      ggomunityCount: 0,
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.input = to.query.input
    next()
  },
  watch: {
    input(v) {
      this.getList();
    }
  },
  created() {
    if (!this.input) location.href = '/'
    this.getList();
  },
  methods: {
    async getList() {
      const { result, tip, lab, ggomu } = await this.$api.getSearchList({ search: this.input });
      if (result === 'success') {
        this.tipList = tip.list
        this.tipCount = tip.total
        this.labList = lab.list
        this.labCount = lab.total
        this.ggomunityList = ggomu.list
        this.ggomunityCount = ggomu.total
      }
    },
    goDetail(target, id) {
      this.setBeforeContent()
      this.$router.push(`/${target}/${id}`)
    },
    more(type) {
      this.$router.push(`/search/${type}?input=${this.input}`)
    }
  }
}
</script>
